<template>
  <div>
    <div class="profile">Profile Photo</div>
    <!-- <div class="proImg">
      <el-upload
        class="avatar-uploader"
        action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"
        :show-file-list="false"
      >
        <el-avatar :size="140" :src="circleUrl" />
      </el-upload>
      <div class="imgWord">
        <h3>Main Profile Photo</h3>
        <p>
          Upload new Profile Photos by clicking on the camera icon. You'll be
          able to crop your pictures in 3 different sizes with our cropping
          tool!
        </p>
        <h6>More Info</h6>
      </div>
      <ul class="imgMessg">
        <h4>Technical Requirements</h4>
        <li>Acceptable image types are JPG, JPEG, PNG</li>
        <li>
          Recommended image type is JPG, all non-JPG files will be converted
        </li>
        <li>Minimum picture size is 1600 x 1200 pixels</li>
        <li>Maximum file size is 15 MB per photo</li>
      </ul>
    </div> -->
    <div class="proPhoto">
      <h3>Live Cover Photo</h3>
      <p>Upload your best live cover image and you will get more likes.</p>
      <ImageUpload
        v-model:imgUrl="da.livePhoto"
        :limit="1"
        @update:imgUrl="updateimgUrl"
      ></ImageUpload>
    </div>
    <div class="proPhoto">
      <h3>
        Profile Photos <span>{{ fileList.length }}/12</span>
      </h3>
      <el-upload
        v-model:file-list="fileList"
        :limit="12"
        :action="uploadImgUrl"
        :headers="headers"
        :show-file-list="fasle"
        list-type="picture-card"
        :before-upload="beforeUpload"
        :on-preview="handlePictureCardPreview"
        :on-remove="handleRemove"
        :on-success="onSuccess"
        :on-exceed="handleExceed"
      >
        <el-icon>
          <Plus />
        </el-icon>
      </el-upload>
      <el-dialog v-model="dialogVisible">
        <img
          style="width: 100%; height: 100%"
          w-full
          :src="dialogImageUrl"
          alt="Preview Image"
        />
      </el-dialog>
    </div>

    <div class="proPhoto">
      <h3>Profile Video url:</h3>
      <el-input v-model="profileUrlVal" style="width: 300px" />
    </div>
    <div style="margin-left:30px;">
      <div>
        <el-button type="primary" @click="urlSubmit">Submit</el-button>
      </div>
    </div>

    <!-- <div class="box" ref="testBox" :style="{ top: y + 'px', left: x + 'px' }"></div> -->

    <!-- <div>
      <userAvatar />
    </div> -->
  </div>
</template>
<script setup name="ProfilePhoto">
import { ref, onMounted, reactive, onBeforeMount } from "vue";
// import { postImage } from '@/api/center.js'
import { Plus } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import ImageUpload from "@/components/ImageUpload";
import { getToken } from "@/utils/auth";
// import userAvatar from "@/components/userAvatar";

import {
  reviseConsulantInformation,
  updateImageList,
  getConInformation,
  getMoreImage,
  delMoreImge,
} from "@/api/center.js";

const props = defineProps({
  consultantId: {
    type: Number || "",
    default: "",
  },
});
const headers = ref({
  Authorization: getToken(),
});
const profileUrlVal = ref();
const URL = process.env.VUE_APP_BASE_API;
const ImageUrl = process.env.VUE_APP_BASE_ImageUrl;
const uploadImgUrl = process.env.VUE_APP_BASE_API + "/file/upload";
const user = ref({ name: "zhangshan" });
const params = ref({});

onMounted(() => {
  // console.log(props.consultantId, 'props.listImage')
  // console.log(window.localStorage.getItem('token'), "window.localStorage.getItem('token')")
  getInformation();
  getImageMore();
});

const fileList = ref([]);

const dialogImageUrl = ref("");
const dialogVisible = ref(false);

const testBox = ref(null);
const Disx = ref(0);
const Disy = ref(0);
const x = ref(0);
const y = ref(0);

const FnStart = function (ev) {
  Disx.value = ev.pageX - x.value;
  Disy.value = ev.pageY - y.value;

  document.onmousemove = FnMove;
  document.onmouseup = FnUp;
};

const FnMove = function (ev) {
  x.value = ev.pageX - Disx.value;
  y.value = ev.pageY - Disy.value;
  ev.preventDefault && ev.preventDefault();
};

const FnUp = function () {
  document.onmousemove = null;
  document.onmouseup = null;
};

const urlSubmit = () => {
  if (!profileUrlVal.value) {
    return;
  }

  params.value = {
    ...params.value,
    profileUrl: profileUrlVal.value,
  };
  reviseConsulantInformation(params.value).then((res) => {
    ElMessage({
      message: "Modified successfully",
      type: "success",
    });
    getInformation();
  });
};

onMounted(() => {
  // testBox.value.onmousedown = FnStart;
});

const handleRemove = async (file, list) => {
  console.log(file.id, "wwwww");
  delMoreImge(file.id).then((res) => {
    if (res.code == 200) {
      ElMessage({
        message: "Successfully deleted.",
        type: "success",
      });
      getImageMore();
    } else {
    }
  });
};

function removeImg() {
  console.log(fileList.value);
  fileList.value.pop();
}

const handlePictureCardPreview = (uploadFile) => {
  dialogImageUrl.value = uploadFile.url;
  dialogVisible.value = true;
};

const da = reactive({
  livePhoto: "",
  fileList: [],
  ImageUrl: process.env.VUE_APP_BASE_ImageUrl,
});
const circleUrl = ref(
  "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
);

const onTest = () => {
  let audio = document.getElementById("myAudio");
  audio.play(); // 播放音频
  let a = 0;
  setTimeout(() => {
    a += 1;
    console.log(a, "定时器");
    pauseAndDestroyAudio();
  }, 30000);
};

const onTest1 = () => {
  let audio = document.getElementById("myAudio");
  audio.pause(); // 暂停音频
};

function pauseAndDestroyAudio() {
  let audio = document.getElementById("myAudio");
  audio.pause(); // 暂停音频
  audio.currentTime = 0; // 将播放位置设置回起始位置
  audio.removeAttribute("loop"); // 移除loop属性，确保不再循环播放

  // 销毁音频元素（可选）
  audio.remove(); // 从DOM中移除元素
}

function onSuccess(res, file) {
  if (res.code === 200) {
    let url = ImageUrl + res.data.url;
    removeImg();
    addFile(res.data.url);
    // this.uploadList.push({ name: res.data.name, url: url })
    // console.log(this.uploadList, 'this.uploadList')
  } else {
    this.number--;
  }
}

async function addFile(url) {
  const param = {
    imageUrl: url,
    consultantId: props.consultantId,
    imageType: 1,
  };
  updateImageList(param).then((res) => {
    console.log(res, ";res");
    res.data.url = ImageUrl + res.data.imageUrl;
    fileList.value.unshift(res.data);
    console.log(res, "多张");
    // getImageMore()
  });
}
//图片校验
const beforeUpload = (file) => {
  // 允许上传的文件格式列表
  let acceptList = ["png", "jpg", "jpeg", "mp4", "mov"];
  // 根据文件名获取文件的后缀名
  let fileType = file.name.split(".").pop().toLowerCase();
  // 判断文件格式是否符合要求
  if (acceptList.indexOf(fileType) == -1) {
    ElMessage.error("File format does not match!");
    return false;
  }
  // 判断文件大小是否符合要求
  if (file.size / 1024 / 1024 > 1) {
    ElMessage.error("The size of the uploaded file cannot exceed 1 MB !");
    return false;
  }
};

// const dialogVisible = ref(false)
// const dialogImageUrl = ref('')
const getInformation = () => {
  getConInformation().then((res) => {
    console.log(res, "resres");
    if (res.code == 200) {
      da.livePhoto = res.data.listImage;
      params.value = res.data;
      profileUrlVal.value = res.data.profileUrl;
    }
  });
};

const getImageMore = () => {
  getMoreImage().then((res) => {
    fileList.value = res.rows.map((item) => {
      item.name = item.nickname;
      item.url = ImageUrl + item.imageUrl;
      return item;
    });
    console.log(fileList.value, "获取多张");
  });
};

/**
 *
 * @param {String} url
 */
const updateimgUrl = (url) => {
  const param = { listImage: url, id: props.consultantId };
  reviseConsulantInformation(param).then((res) => {
    if (res.code == 200) {
    }
  });
};

//上传多张图片
/**
 *
 * @param {Array} fileList
 */
// 你这里要改成多张保存的格式，多张都在fileList里面的url
const updateimgUrlyy = (fileList) => {
  console.log(fileList, "多张");
  da.fileList = fileList;
  fileList.forEach((item) => {
    const param = {
      imageUrl: item.url,
      consultantId: props.consultantId,
      imageType: 1,
    };
    updateImageList(param).then((res) => {
      console.log(res, "多张");
      // getImageMore()
    });
  });
  //imageType为1是上传图片   为2是上传视频
};

// //多张图片删除
const handleDeleteImg = (file) => {
  console.log(file, "删除多张");
  // delMoreImge({id:file.id})
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 768px) {
  :deep(.el-upload-list) {
    --el-upload-list-picture-card-size: 122px;
  }
}

.profile {
  line-height: 50px;
  border-bottom: 1px solid #c2bcbc;
  padding-left: 20px;
  color: #02758a;
}

.box {
  width: 100px;
  height: 100px;
  background-color: pink;
  cursor: pointer;
  position: fixed;
  // top:100px;
}

.proImg {
  display: flex;
  // justify-content: space-between;
  margin: 15px 20px;
  padding-bottom: 40px;
  border-bottom: 1px solid #c2bcbc;

  .imgWord {
    width: 400px;
    padding: 30px;

    h3 {
      color: rgb(86, 51, 117);
      margin-bottom: 10px;
    }

    p {
      color: rgb(143, 119, 164);
      font-size: 12px;
    }

    h6 {
      color: rgb(86, 51, 117);
      margin-top: 12px;
      cursor: pointer;
    }
  }

  .imgMessg {
    padding: 10px 40px;
    background-color: rgb(245, 241, 248);

    h4 {
      margin-bottom: 10px;
      color: rgb(86, 51, 117);
    }

    li {
      font-size: 12px;
      color: rgb(143, 119, 164);
      line-height: 30px;
    }
  }
}

.proPhoto {
  padding: 10px 30px;

  h3 {
    line-height: 30px;
    color: rgb(143, 119, 164);
  }

  p {
    line-height: 30px;
    color: rgb(143, 119, 164);
  }
}
</style>
